import {
  DescriptiveText,
  ResponsiveTableWrapper,
  TableFilters,
  TextAlignment,
  TextSize,
  styled,
} from '@yarmill/components';
import { AsyncStatus } from '@yarmill/types';
import {
  TableFiltersStore,
  useSize,
  useUsersStore,
  userMatchesTableFilter,
} from '@yarmill/utils';
import { observer, useLocalObservable } from 'mobx-react-lite';
import {
  PropsWithChildren,
  RefObject,
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useMemo,
  useRef,
  ReactElement,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
import { UserRow } from './user-row';
import { createUserStoresSortFunction } from './utils/create-user-stores-sort-function';

interface VirtualizedUsersListProps {
  readonly pageMainContentRef: RefObject<HTMLDivElement>;
  readonly usersHeaderRef: RefObject<HTMLDivElement>;
  readonly sidebarRef: RefObject<HTMLDivElement>;
}

const CONTENT_BOX_PADDING_MOBILE = 12;
const CONTENT_BOX_PADDING_DESKTOP = 26;
const GAP = 16;

function getListHeight(
  pageHeight: number,
  headerHeight: number,
  sidebarHeight: number
): number {
  return (
    (window.innerWidth >= 768
      ? pageHeight
      : window.innerHeight - 55 - 24 - 10) -
    headerHeight -
    (window.innerWidth >= 768 ? 0 : sidebarHeight) -
    (window.innerWidth >= 768
      ? CONTENT_BOX_PADDING_DESKTOP
      : CONTENT_BOX_PADDING_MOBILE) *
      2 -
    GAP
  );
}

const TableHeader = styled.div`
  align-content: flex-end;
  display: grid;
  grid-template-columns: 40px 60vw 116px 150px 80px 80px 80px 60px;
  z-index: 1;
  background-color: #fff;

  @media (min-width: 560px) {
    grid-template-columns: 40px 1fr 116px 150px 80px 80px 80px 60px;
  }
  & > * {
    padding: 8px;
  }
`;

const TableHeaderText = styled(DescriptiveText)`
  display: flex;
  align-items: center;
  justify-content: ${({ textAlign }) =>
    textAlign === TextAlignment.center ? 'center' : 'flex-start'};
`;

const FiltersContext = createContext(new TableFiltersStore());

const HeaderWrapper = observer(
  forwardRef<HTMLDivElement, PropsWithChildren>(
    ({ children, ...rest }, ref) => {
      const filtersStore = useContext(FiltersContext);
      const sortConfig = filtersStore.sortConfig;
      const intl = useIntl();
      const openUserNameFilter = useRef<(() => void) | null>(null);

      useEffect(() => {
        function onKeyDown(e: KeyboardEvent) {
          if ((e.ctrlKey || e.metaKey) && e.key.toLowerCase() === 'f') {
            e.preventDefault();
            e.stopPropagation();
            openUserNameFilter.current?.();
          }
        }
        document.addEventListener('keydown', onKeyDown);

        return () => {
          document.removeEventListener('keydown', onKeyDown);
        };
      }, []);

      return (
        <div ref={ref} {...rest}>
          <TableHeader
            style={{
              top: 0,
              left: 0,
              width: '100%',
              height: 40,
              position: 'sticky',
            }}
            key={0}
          >
            <div />
            <TableHeaderText
              size={TextSize.s12}
              medium
              textAlign={TextAlignment.left}
            >
              <FormattedMessage id="settings.users.table.header.firstName" />
              <TableFilters
                attributeId="userName"
                onChange={filtersStore.handleFilterChange}
                filter={filtersStore.getFilter('userName')}
                sort={sortConfig?.sort === 'userName' ? sortConfig.order : null}
                filterType="text"
                openFiltersRef={openUserNameFilter}
              />
            </TableHeaderText>
            <TableHeaderText
              size={TextSize.s12}
              medium
              textAlign={TextAlignment.center}
            >
              <FormattedMessage id="settings.users.table.header.role" />
              <TableFilters
                attributeId="role"
                onChange={filtersStore.handleFilterChange}
                filter={filtersStore.getFilter('role')}
                sort={sortConfig?.sort === 'role' ? sortConfig.order : null}
                filterType="options"
                options={[
                  {
                    label: intl.formatMessage({
                      id: 'settings.users.role.admin',
                    }),
                    value: 'admin',
                  },
                  {
                    label: intl.formatMessage({
                      id: 'settings.users.role.coach',
                    }),
                    value: 'coach',
                  },
                  {
                    label: intl.formatMessage({
                      id: 'settings.users.role.athlete',
                    }),
                    value: 'athlete',
                  },
                ]}
              />
            </TableHeaderText>
            <TableHeaderText
              size={TextSize.s12}
              medium
              textAlign={TextAlignment.center}
            >
              <FormattedMessage id="settings.users.table.header.status" />
              <TableFilters
                attributeId="status"
                onChange={filtersStore.handleFilterChange}
                filter={filtersStore.getFilter('status')}
                sort={sortConfig?.sort === 'status' ? sortConfig.order : null}
                filterType="options"
                options={[
                  {
                    label: intl.formatMessage({
                      id: `settings.users.accountState.1`,
                    }),
                    value: '1',
                  },
                  {
                    label: intl.formatMessage({
                      id: `settings.users.accountState.3`,
                    }),
                    value: '3',
                  },
                  {
                    label: intl.formatMessage({
                      id: `settings.users.accountState.5`,
                    }),
                    value: '5',
                  },
                ]}
              />
            </TableHeaderText>
            <TableHeaderText
              size={TextSize.s12}
              medium
              textAlign={TextAlignment.center}
            >
              <FormattedMessage id="settings.users.table.header.groups" />
              <TableFilters
                attributeId="groups"
                onChange={filtersStore.handleFilterChange}
                filter={filtersStore.getFilter('groups')}
                sort={sortConfig?.sort === 'groups' ? sortConfig.order : null}
                filterType="number"
              />
            </TableHeaderText>
            <TableHeaderText
              size={TextSize.s12}
              medium
              textAlign={TextAlignment.center}
            >
              <FormattedMessage id="settings.users.table.header.memberSince" />
              <TableFilters
                attributeId="memberSince"
                onChange={filtersStore.handleFilterChange}
                filter={filtersStore.getFilter('memberSince')}
                sort={
                  sortConfig?.sort === 'memberSince' ? sortConfig.order : null
                }
                filterType="date"
              />
            </TableHeaderText>
            <TableHeaderText
              size={TextSize.s12}
              medium
              textAlign={TextAlignment.center}
            >
              <FormattedMessage id="settings.users.table.header.memberUntil" />
              <TableFilters
                attributeId="memberUntil"
                onChange={filtersStore.handleFilterChange}
                filter={filtersStore.getFilter('memberUntil')}
                sort={
                  sortConfig?.sort === 'memberUntil' ? sortConfig.order : null
                }
                filterType="date"
              />
            </TableHeaderText>
            <div />
          </TableHeader>

          {children}
        </div>
      );
    }
  )
);

export const VirtualizedUsersList = observer(function VirtualizedUsersList({
  usersHeaderRef,
  pageMainContentRef,
  sidebarRef,
}: VirtualizedUsersListProps): ReactElement {
  const usersStore = useUsersStore();
  const { height: pageHeight } = useSize(pageMainContentRef);
  const { height: headerHeight } = useSize(usersHeaderRef);
  const { height: sidebarHeight } = useSize(sidebarRef);
  const listHeight = getListHeight(
    pageHeight ?? 0,
    headerHeight ?? 0,
    sidebarHeight ?? 0
  );
  const filtersStore = useLocalObservable(() => new TableFiltersStore());
  // biome-ignore lint/correctness/useExhaustiveDependencies:
  const filteredUsers = useMemo(
    () =>
      usersStore.allUsers
        .filter(user => userMatchesTableFilter(user, filtersStore.filters))
        .sort(createUserStoresSortFunction(filtersStore.sortConfig)),
    [
      filtersStore.filters,
      filtersStore.sortConfig,
      usersStore.allUsers,
      filtersStore.jsFilters,
    ]
  );
  const isLoaded =
    usersStore.status === AsyncStatus.resolved &&
    usersStore.deactivatedUsersStatus === AsyncStatus.resolved;

  return (
    <ResponsiveTableWrapper>
      <FiltersContext.Provider value={filtersStore}>
        <AutoSizer style={{ height: listHeight }}>
          {({ width }) => (
            <VariableSizeList
              itemSize={index => (index === 0 ? 40 : 53)}
              height={listHeight}
              itemCount={isLoaded ? filteredUsers.length + 1 : 1}
              width={width > 822 ? width : 822}
              innerElementType={HeaderWrapper}
            >
              {({ index, style }) =>
                index === 0 ? null : ( // 0 for sticky header
                  <UserRow
                    key={filteredUsers[index - 1].id}
                    isVirtualized
                    user={filteredUsers[index - 1]}
                    style={style}
                  />
                )
              }
            </VariableSizeList>
          )}
        </AutoSizer>
      </FiltersContext.Provider>
    </ResponsiveTableWrapper>
  );
});
