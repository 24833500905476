import { SortConfig, UserStore, GroupStore } from '@yarmill/types';
import { sortUserStores } from '@yarmill/utils';
import { sortUserStoresByGroups } from './sort-user-stores-by-groups';
import { sortUserStoresByRole } from './sort-user-stores-by-role';
import { sortUserStoresByStatus } from './sort-user-stores-by-status';
import { sortUserStoresByMemberSince } from './sort-user-stores-by-member-since';
import { sortUserStoresByMemberUntil } from './sort-user-stores-by-member-until';
import { sortByDate } from './sort-by-date';

export function createUserStoresSortFunction(
  sortConfig: SortConfig | null,
  group?: GroupStore
): (a: UserStore, b: UserStore) => number {
  return (a: UserStore, b: UserStore) => {
    if (sortConfig === null || sortConfig.sort === 'userName') {
      return !sortConfig || sortConfig.order === 'ASC'
        ? sortUserStores(a, b)
        : sortUserStores(b, a);
    } else if (sortConfig.sort === 'role') {
      return !sortConfig || sortConfig.order === 'ASC'
        ? sortUserStoresByRole(a, b)
        : sortUserStoresByRole(b, a);
    } else if (sortConfig.sort === 'status') {
      return !sortConfig || sortConfig.order === 'ASC'
        ? sortUserStoresByStatus(a, b)
        : sortUserStoresByStatus(b, a);
    } else if (sortConfig.sort === 'groups') {
      return !sortConfig || sortConfig.order === 'ASC'
        ? sortUserStoresByGroups(a, b)
        : sortUserStoresByGroups(b, a);
    } else if (sortConfig.sort === 'memberSince') {
      return !sortConfig || sortConfig.order === 'ASC'
        ? sortUserStoresByMemberSince(a, b)
        : sortUserStoresByMemberSince(b, a);
    } else if (sortConfig.sort === 'memberUntil') {
      return !sortConfig || sortConfig.order === 'ASC'
        ? sortUserStoresByMemberUntil(a, b)
        : sortUserStoresByMemberUntil(b, a);
    } else if (sortConfig.sort === 'addedToGroup') {
      if (!group) {
        return 0;
      }
      return !sortConfig || sortConfig.order === 'ASC'
        ? sortByDate(
            group?.getUserValidityDate(a.id)?.ValidFrom,
            group?.getUserValidityDate(b.id)?.ValidFrom
          )
        : sortByDate(
            group?.getUserValidityDate(b.id)?.ValidFrom,
            group?.getUserValidityDate(a.id)?.ValidFrom
          );
    } else if (sortConfig.sort === 'removedFromGroup') {
      if (!group) {
        return 0;
      }
      return !sortConfig || sortConfig.order === 'ASC'
        ? sortByDate(
            group?.getUserValidityDate(a.id)?.ValidTo,
            group?.getUserValidityDate(b.id)?.ValidTo
          )
        : sortByDate(
            group?.getUserValidityDate(b.id)?.ValidTo,
            group?.getUserValidityDate(a.id)?.ValidTo
          );
    }

    return 0;
  };
}
