import moment from 'moment';

export function sortByDate(
  a: string | null | undefined,
  b: string | null | undefined
): number {
  if (!a && b) {
    return 1;
  }

  if (a && !b) {
    return -1;
  }

  if (!a && !b) {
    return 0;
  }

  const sinceA = moment(a);
  const sinceB = moment(b);

  if (sinceA.isSame(sinceB, 'day')) {
    return 0;
  }

  return sinceA.isBefore(sinceB) ? -1 : 1;
}
