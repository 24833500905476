import { ContextLayerOptions } from '@yarmill/types';
import {
  createDateFormatValidator,
  extractDelimiters,
  useLayer,
} from '@yarmill/utils';
import { forwardRef, ReactElement, useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { ContextLayerPositionProps } from '../context-layer';
import { Datepicker, DatepickerProps } from '../datepicker';
import { LayerPortal } from '../layer-portal';
import { PatternInput, PatternInputProps } from './pattern-input';

const StyledPatternInputDateWrapper = styled.div`
  position: relative;
`;

interface PatternInputDateProps extends PatternInputProps, DatepickerProps {
  defaultValue?: string;
  value?: string;
}

function getDatepickerPosition(inputRect: DOMRect): ContextLayerPositionProps {
  const datepickerDimensions = { width: 320, height: 394 };
  const offset = 10;
  let top = inputRect.bottom;
  let left = inputRect.left;
  let horizontalAlignment: 'left' | 'right' = 'right';
  let verticalAlignment: 'top' | 'bottom' = 'bottom';

  if (
    top + datepickerDimensions.height + offset > window.innerHeight &&
    datepickerDimensions.height + offset < top
  ) {
    verticalAlignment = 'top';
    top = inputRect.top;
  }

  if (left + datepickerDimensions.width + offset > window.innerWidth) {
    horizontalAlignment = 'left';
  }

  if (window.innerWidth < 768) {
    top = window.innerHeight / 2 - datepickerDimensions.height / 2;
    left = window.innerWidth / 2 - datepickerDimensions.width / 2;
    verticalAlignment = 'bottom';
    horizontalAlignment = 'right';
  }

  return {
    top,
    left,
    horizontalAlignment,
    verticalAlignment,
  };
}

export const PatternInputDate = forwardRef<
  HTMLInputElement,
  PatternInputDateProps
>(function PatternInputDate(props, passedRef): ReactElement {
  const {
    autoFocus,
    label,
    id,
    pattern,
    name,
    delimiter = extractDelimiters(pattern),
    validateValue = createDateFormatValidator(pattern),
    selectRange,
    error,
    defaultValue,
    minBookingDate,
    maxBookingDate,
    todayButtonLabel,
    variant,
    readOnly,
    value,
    onChange,
    onBlur,
    onFocus,
    noLabel,
    noError,
    disabled,
    tooltip,
  } = props;
  const internalRef = useRef<HTMLInputElement>(null);
  const isControlled = useRef(typeof value !== 'undefined');
  const [uncontrolledValue, setUncontrolledValue] = useState<
    string | undefined
  >(defaultValue);
  const ref = passedRef || internalRef;
  const val = isControlled.current ? value : uncontrolledValue;

  const datepickerLayer = useLayer('context', {
    showShim: false,
    closeOnShimClick: true,
  });

  const onDateInputChange = useCallback(
    (newValue: string): void => {
      onChange?.(newValue);
      datepickerLayer.isOpened && datepickerLayer.layer.close();
      if (!isControlled.current) {
        setUncontrolledValue(newValue);
      }
    },
    [onChange, datepickerLayer]
  );

  const onClick = useCallback((): void => {
    if (!datepickerLayer.isOpened) {
      if (typeof ref === 'object' && ref.current) {
        const rect = ref.current?.getBoundingClientRect();
        datepickerLayer.open({
          position: getDatepickerPosition(rect),
          showShim: window.innerWidth < 768,
          onClose: () => {
            console.log(ref);
            setTimeout(() => ref.current?.focus(), 0);
          },
        } as Partial<ContextLayerOptions>);
      }
    }
  }, [datepickerLayer, ref]);

  return (
    <StyledPatternInputDateWrapper>
      <PatternInput
        autoFocus={autoFocus}
        label={label}
        id={id}
        readOnly={readOnly}
        variant={variant}
        name={name}
        pattern={pattern}
        delimiter={delimiter}
        error={error}
        onChange={onDateInputChange}
        validateValue={validateValue}
        value={val}
        onClick={disabled || readOnly ? undefined : onClick}
        defaultValue={defaultValue}
        onBlur={onBlur}
        onFocus={onFocus}
        ref={ref}
        noLabel={noLabel}
        noError={noError}
        disabled={disabled}
        tooltip={tooltip}
      />
      <LayerPortal
        layerHandle={datepickerLayer}
        getContent={() => (
          <Datepicker
            value={val}
            format={pattern}
            selectRange={selectRange}
            minBookingDate={minBookingDate}
            maxBookingDate={maxBookingDate}
            todayButtonLabel={todayButtonLabel}
            onChange={onDateInputChange}
          />
        )}
      />
    </StyledPatternInputDateWrapper>
  );
});
