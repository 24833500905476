import {
  EvidenceTableFilters,
  NumberFilter,
  UserInGroupValidity,
  UserStore,
} from '@yarmill/types';
import { filterUserByName } from './filter-user-by-name';
import { filterByDate } from './filter-by-date';

export function userMatchesTableFilter(
  user: UserStore,
  filtersStore: EvidenceTableFilters,
  userInGroupValidity?: UserInGroupValidity
): boolean {
  const nameFilter = filtersStore.get('userName');
  const roleFilter = filtersStore.get('role');
  const statusFilter = filtersStore.get('status');
  const groupsFilter = filtersStore.get('groups');
  const memberSinceFilter = filtersStore.get('memberSince');
  const memberUntilFilter = filtersStore.get('memberUntil');
  const removedFromGroupFilter = filtersStore.get('removedFromGroup');
  const addedToGroupFilter = filtersStore.get('addedToGroup');

  if (nameFilter) {
    if (!filterUserByName(user, nameFilter as string)) {
      return false;
    }
  }

  if (roleFilter) {
    if (Array.isArray(roleFilter)) {
      if (!roleFilter.includes(user.internalUser.Role)) {
        return false;
      }
    } else if (roleFilter !== user.internalUser.Role) {
      return false;
    }
  }

  if (statusFilter) {
    if (Array.isArray(statusFilter)) {
      if (!statusFilter.includes(String(user.internalUser.AccountStateId))) {
        return false;
      }
    } else if (statusFilter !== String(user.internalUser.AccountStateId)) {
      return false;
    }
  }

  if (groupsFilter) {
    const numberFilter = groupsFilter as unknown as NumberFilter;
    switch (numberFilter.type) {
      case 'match': {
        if (Number(numberFilter.values[0]) !== user.groups.length) {
          return false;
        }
        break;
      }
      case 'lessorequal': {
        if (!(Number(numberFilter.values[0]) >= user.groups.length)) {
          return false;
        }
        break;
      }
      case 'greaterorequal': {
        if (!(Number(numberFilter.values[0]) <= user.groups.length)) {
          return false;
        }
        break;
      }
      case 'range': {
        if (
          !(
            Number(numberFilter.from) <= user.groups.length &&
            Number(numberFilter.to) >= user.groups.length
          )
        ) {
          return false;
        }
        break;
      }
    }
  }

  if (memberSinceFilter) {
    if (
      !filterByDate(user.internalUser.MemberSince, memberSinceFilter as never)
    ) {
      return false;
    }
  }

  if (memberUntilFilter) {
    if (
      !filterByDate(user.internalUser.MemberUntil, memberUntilFilter as never)
    ) {
      return false;
    }
  }

  if (addedToGroupFilter) {
    if (
      !userInGroupValidity?.ValidFrom ||
      !filterByDate(userInGroupValidity.ValidFrom, addedToGroupFilter as never)
    ) {
      return false;
    }
  }

  if (removedFromGroupFilter) {
    if (
      !userInGroupValidity?.ValidTo ||
      !filterByDate(
        userInGroupValidity.ValidTo,
        removedFromGroupFilter as never
      )
    ) {
      return false;
    }
  }

  return true;
}
