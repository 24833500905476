import { DateFilter } from '@yarmill/types';
import moment from 'moment';

export function filterByDate(date: string | null, filter: DateFilter): boolean {
  if (!date) {
    return false;
  }

  const dateValue = moment(date);
  switch (filter.type) {
    case 'thisweek': {
      const startOfWeek = moment().startOf('isoWeek');
      const endOfWeek = moment().endOf('isoWeek');
      return dateValue.isBetween(startOfWeek, endOfWeek, 'day', '[]');
    }
    case 'thismonth': {
      const startOfMonth = moment().startOf('month');
      const endOfMonth = moment().endOf('month');
      return dateValue.isBetween(startOfMonth, endOfMonth, 'day', '[]');
    }
    case 'lastweek': {
      const startOfLastWeek = moment().subtract(1, 'week').startOf('isoWeek');
      const endOfLastWeek = moment().subtract(1, 'week').endOf('isoWeek');
      return dateValue.isBetween(
        startOfLastWeek,
        endOfLastWeek,
        undefined,
        '[]'
      );
    }
    case 'lastmonth': {
      const startOfLastMonth = moment().subtract(1, 'month').startOf('month');
      const endOfLastMonth = moment().subtract(1, 'month').endOf('month');
      return dateValue.isBetween(
        startOfLastMonth,
        endOfLastMonth,
        undefined,
        '[]'
      );
    }
    case 'range': {
      const startDate = moment(filter.from);
      const endDate = moment(filter.to);
      return dateValue.isBetween(startDate, endDate, 'day', '[]');
    }
    case 'match': {
      const matchDate = moment(filter.values[0]);
      return dateValue.isSame(matchDate, 'day');
    }
  }

  return true;
}
